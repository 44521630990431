// Simply returns values from deeply nested appConfig and enables better error handling.
// So insead of: appConfig?.tenantConfig?.theme?.brandLogoDarkUrl
// We can do: useTenantTheme().brandLogoDarkUrl
export const useTenantTheme = () => {
  const appConfig = useAppConfig()
  const theme = appConfig?.tenantConfig?.theme
  if (!theme) {
    throw createError('Tenant theme config not found - please check your theme settings.')
  }
  return theme
}
